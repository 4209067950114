import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./App.css";

import Sandbox from "./pages/Sandbox";
import GlitchedHome from "./pages/GlitchedHome";
import GlichedDemo from "./pages/GlitchedDemo";

import { VisibleElementsContextProvider } from "./contexts/visibleElementsContext";

const App = () => {
  let location = useLocation();

  return (
    <div className="App">
      <header className="App-header"> </header>
      <VisibleElementsContextProvider>
        <Switch location={location}>
          <Route path="/" exact component={GlitchedHome} />
          <Route path="/glitched" exact component={GlichedDemo} />
          <Route path="/sandbox" exact component={Sandbox} />
        </Switch>
      </VisibleElementsContextProvider>
    </div>
  );
};

export default App;
