import styled from 'styled-components';
import { Html } from "@react-three/drei"


const FirstDiv = styled(Html)`
    color: white;
    width:250px;
    padding: 1.5rem;
    transform: translate3d(0, -100%, 0) !important;

    @media screen and (min-width: 53em) {
        
        padding: 0 0 2rem 3.7rem;
    }


`

export {FirstDiv}