import { createRef } from "react";
const state = {
  sections: 2,
  pages: 1,
  zoom: 75,
  paragraphs: [
    {
      offset: 0,
      factor: 1.75,
      header: "Glitching",
      image: "/glitched3.jpg",
      aspect: 1.51,
      text: "Making images glitch using WebGL (based on the RGB displacement from react-three-fiber...) ",
      link: "https://unsplash.com/photos/Mn9Fa_wQH-M",
    },
  ],

  top: createRef(),
  validScroll: createRef(),
};

export default state;
