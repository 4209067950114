import React from "react";
import {
  MobileContainer,
  MobileTitle,
  MobileSubtitle,
  LinksContainer,
  Link,
} from "./styled";
const MobileDisclaimer = () => {
  return (
    <MobileContainer>
      <MobileTitle>This page is not intended to be mobile first.</MobileTitle>
      <LinksContainer>
        <MobileSubtitle>You can contact me here:</MobileSubtitle>
        <Link target="blank" href="https://www.linkedin.com/in/lucianolupo">
          Linkedin
        </Link>
        <Link target="blank" href="https://twitter.com/lupo0x">
          Twitter
        </Link>
      </LinksContainer>

      <MobileSubtitle>
        If you had the time, come back to the site but from a desktop device!
      </MobileSubtitle>
    </MobileContainer>
  );
};

export default MobileDisclaimer;
