

const Sandbox = () => {
  return (
    <div>
      <div style={{ width: '100vw', height: '100vh' }}>
        <p>Hello World</p>
      </div>
    </div>
  );
};

export default Sandbox;
