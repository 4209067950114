import React from "react";
import { useThree } from "@react-three/fiber";

const ViewPortContext = React.createContext({});

export const ViewPortContextProvider = ({ children }) => {
  const { size, viewport } = useThree();
  const isMobile = size.width < 700;
  return (
    <ViewPortContext.Provider value={{ isMobile, size, viewport }}>
      {children}
    </ViewPortContext.Provider>
  );
};

export default ViewPortContext;
