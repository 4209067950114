import React, { useMemo, useRef } from "react";

import { useLoader } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { TextureLoader, LinearFilter } from "three";
import { MultilineText } from "../Text";
import Plane from "../Plane";
import { Block, useBlock } from "../Blocks";
import state from "../../store";
import "../../styles.css";
import Paragraph from "../Paragraph";
import { FirstDiv } from "./styled";
import useOnScreen from "../../../../hooks/useOnScreen";
const Content = ({ history }) => {
  const images = useLoader(
    TextureLoader,
    state.paragraphs.map(({ image }) => image)
  );
  const backgroundImage = useLoader(TextureLoader, "/glitched1.jpg");

  useMemo(
    () => images.forEach((texture) => (texture.minFilter = LinearFilter)),
    [images]
  );

  const {
    contentMaxWidth: w,
    canvasWidth,
    canvasHeight,
    isMobile,
  } = useBlock();
  const lastElement = useRef();
  state.lastElement.current = lastElement.current;

  state.validScroll.current = !useOnScreen(lastElement);

  return (
    <>
      <Block factor={1} offset={0}>
        <Block factor={1.2}>
          {(isMobile && (
            <MultilineText
              left
              size={w * 0.06}
              position={[-w / 2, 2, -1]}
              color="#2FE8C3"
              lineHeight={w / 9}
              text={
                "Here I throw out \nstuff that I make \nin my spare time, \njust for fun!"
              }
            />
          )) || (
            <MultilineText
              left
              size={w * 0.025}
              position={[-w / 1.5, 2, -1]}
              color="#2FE8C3"
              lineHeight={w / 20}
              text={
                "Here I throw out stuff that \nI make in my spare time, just for fun!"
              }
            />
          )}
        </Block>
        <Block factor={1.0}>
          <FirstDiv
            position={[
              isMobile ? canvasWidth / 10 : canvasWidth / 5,
              -canvasHeight / 2,
              0,
            ]}
          >
            <a target="blank" href="https://www.linkedin.com/in/lucianolupo">
              This is NOT a resume, {isMobile ? <br /> : " "}for that check my
              Linkedin ;).
            </a>
          </FirstDiv>
        </Block>
      </Block>

      {state.paragraphs.map((props, index) => (
        <Paragraph
          key={index}
          index={index}
          {...props}
          image={images[index]}
          image2={images[index]}
          history={history}
        />
      ))}

      <Block factor={1} offset={0} absolute>
        <Plane
          args={[canvasWidth, canvasHeight, 32, 32]}
          map={backgroundImage}
          opacity={0.5}
          color={"#2fe8c3"}
          position={[0, 0, -10]}
        />
      </Block>
      <Block factor={1.25} offset={2.3}>
        <Html
          style={{ color: "white" }}
          className="bottom-left"
          position={[-canvasWidth / 2, -canvasHeight / 2, 0]}
          ref={lastElement}
        >
          Thanks for your time.
        </Html>
      </Block>
    </>
  );
};

export default Content;
