import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;
const ScrollArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  //pointer-events: none;
`;
export { Container, ScrollArea };
