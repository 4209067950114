import styled from "styled-components";

const MobileContainer = styled.div`
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`;

const MobileTitle = styled.h2`
  text-align: center;
`;

const MobileSubtitle = styled.p`
  text-align: center;
`;

const LinksContainer = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Link = styled.a`
  margin: 10px 0;
`;

export { MobileContainer, MobileTitle, MobileSubtitle, LinksContainer, Link };
