import React, { createContext, useRef, useContext } from "react";
import { useFrame } from "@react-three/fiber";
import lerp from "lerp";
import state from "../../store";
import ViewPortContext from "../../../../contexts/viewPortContext";

const offsetContext = createContext(0);

const Block = ({ children, offset, factor, absolute, ...props }) => {
  const { offset: parentOffset, sectionHeight } = useBlock();
  const ref = useRef();
  offset = offset !== undefined ? offset : parentOffset;
  useFrame(() => {
    const curY = ref.current.position.y;
    const curTop = state.top.current;
    if (absolute) {
      ref.current.position.y = 0;
    } else if (state.validScroll.current) {
      ref.current.position.y = lerp(curY, (curTop / state.zoom) * factor, 0.01);
    }
  });
  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[0, -sectionHeight * offset * factor, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  );
};

const useBlock = () => {
  const { sections, pages, zoom } = state;
  const { viewport, isMobile } = useContext(ViewPortContext);
  const offset = useContext(offsetContext);
  const viewportWidth = viewport.width * zoom;
  const viewportHeight = viewport.height * zoom;
  const canvasWidth = viewportWidth / zoom;
  const canvasHeight = viewportHeight / zoom;
  const margin = canvasWidth * (isMobile ? 0.2 : 0.1);
  const contentMaxWidth = canvasWidth * (isMobile ? 0.8 : 0.6);
  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1));
  const offsetFactor = (offset + 1.0) / sections;
  return {
    viewport,
    offset,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    isMobile,
    margin,
    contentMaxWidth,
    sectionHeight,
    offsetFactor,
  };
};

export { Block, useBlock };
