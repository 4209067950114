import React from "react";
import { Html } from "@react-three/drei";
import { Text } from "../Text";
import Plane from "../Plane";
import { Block, useBlock } from "../Blocks";
import state from "../../store";

import "../../styles.css";

const Paragraph = ({
  image,
  image2,
  index,
  offset,
  factor,
  header,
  aspect,
  text,
  link,
  history,
}) => {
  const { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock();
  const size = aspect < 1 && !mobile ? 0.65 : 1;
  const alignRight = (canvasWidth - w * size - margin) / 2;
  const pixelWidth = w * state.zoom * size;
  const left = !(index % 2);
  const color = "#2FE8C3";

  const goToLink = () => {
    console.log("==========>", link);

    if (link.external) {
      window.location = link.value;
    } else {
      history.push(`${link.value}`);
    }
  };

  return (
    <Block factor={factor} offset={offset}>
      <group
        position={[left ? -alignRight : alignRight, 0, 0]}
        onClick={goToLink}
      >
        <Plane
          map={image}
          image2={image2}
          args={[1, 1, 32, 32]}
          shift={75}
          size={size}
          aspect={aspect}
          scale={[w * size, (w * size) / aspect, 1]}
          frustumCulled={false}
        />
        <Plane
          map={image}
          args={[1, 1, 32, 32]}
          shift={75}
          size={size}
          aspect={aspect}
          scale={[w * size, (w * size) / aspect, 1]}
          frustumCulled={false}
        />
        <Html
          style={{
            width: pixelWidth / (mobile ? 1 : 2),
            textAlign: left ? "left" : "right",
          }}
          position={[
            left || mobile ? (-w * size) / 2 : 0,
            (-w * size) / 2 / aspect - 0.4,
            1,
          ]}
        >
          <div tabIndex={index}>{text}</div>
        </Html>
        <Text
          left={left}
          right={!left}
          size={w * 0.04}
          color={color}
          top
          position={[
            ((left ? -w : w) * size) / 2,
            (w * size) / aspect / 2 + 0.5,
            -1,
          ]}
        >
          {header}
        </Text>
        <Block factor={0.2}>
          <Text
            opacity={0.5}
            size={w * 0.5}
            color="#1A1E2A"
            position={[
              ((left ? w : -w) / 2) * size,
              (w * size) / aspect / 1,
              -10,
            ]}
          >
            {"0" + (index + 1)}
          </Text>
        </Block>
      </group>
    </Block>
  );
};

export default Paragraph;
