import React, { useState } from "react";

const VisibleElementsContext = React.createContext({});

export const VisibleElementsContextProvider = ({ children }) => {
  const [lastVisible, setLastVisible] = useState(false);
  return (
    <VisibleElementsContext.Provider value={{ lastVisible, setLastVisible }}>
      {children}
    </VisibleElementsContext.Provider>
  );
};

export default VisibleElementsContext;
