import React, { useMemo, useRef } from "react";

import { useLoader } from "@react-three/fiber";
import { TextureLoader, LinearFilter } from "three";
import Plane from "../Plane";
import { Block, useBlock } from "../Blocks";
import state from "../../store";
import "../../styles.css";
import Paragraph from "../Paragraph";
import { FirstDiv } from "./styled";
import useOnScreen from "../../../../hooks/useOnScreen";
const Content = () => {
  const images = useLoader(
    TextureLoader,
    state.paragraphs.map(({ image }) => image)
  );
  const backgroundImage = useLoader(TextureLoader, "/glitched1.jpg");

  useMemo(
    () => images.forEach((texture) => (texture.minFilter = LinearFilter)),
    [images]
  );

  const { canvasWidth, canvasHeight, isMobile } = useBlock();
  const lastElement = useRef();

  state.validScroll.current = !useOnScreen(lastElement);

  return (
    <>
      {state.paragraphs.map((props, index) => (
        <Paragraph
          key={index}
          index={index}
          {...props}
          image={images[index]}
          image2={images[index]}
        />
      ))}

      <Block factor={1.25} offset={1}>
        <FirstDiv
          position={[
            isMobile ? canvasWidth / 10 : canvasWidth / 5,
            -canvasHeight / 2,
            0,
          ]}
          ref={lastElement}
        >
          <a
            target="blank"
            href="https://gist.github.com/LucianoLupo/52561f2062cd970b4960b6e721001f80"
          >
            Go to Github -->
          </a>
        </FirstDiv>
      </Block>

      <Block factor={1} offset={1} absolute>
        <Plane
          args={[canvasWidth, canvasHeight, 32, 32]}
          map={backgroundImage}
          opacity={0.5}
          color={"#2fe8c3"}
          position={[0, 0, -10]}
        />
      </Block>
    </>
  );
};

export default Content;
