import React, { forwardRef, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import lerp from "lerp";
import "../../MyCustomMaterial";
import storedState from "../../store";

const Plane = forwardRef(
  ({ color = "white", shift = 1, opacity, args, map, ...props }, ref) => {
    // const { viewportHeight, offsetFactor } = useBlock()
    const material = useRef();
    let last = storedState.top.current;

    useFrame(({ clock }) => {
      // const { pages, top } = storedState
      const { top } = storedState;

      const elapsedTime = clock.getElapsedTime();
      const randomSignFactor = Math.floor(elapsedTime * 100) % 2 === 0 ? 1 : -1;
      const random = Math.random();
      const random2 = Math.random();

      // material.current.scale = lerp(material.current.scale, offsetFactor - top.current / ((pages - 1) * viewportHeight), 0.1)
      material.current.shift = lerp(
        material.current.shift,
        ((top.current - last) / shift) * 1.5,
        0.1
      );
      const randomDispFactor =
        (1 + Math.sin(material.current.shift / 10) + random / 10000) *
        randomSignFactor;
      material.current.dispFactor =
        Math.floor(random2 * 100) > 91
          ? randomDispFactor
          : material.current.dispFactor;
      material.current.dispFactor =
        Math.floor(random2 * 100) > 93 ? 0 : material.current.dispFactor;

      last = top.current;
    });
    return (
      <mesh ref={ref} {...props}>
        <planeBufferGeometry args={args} />
        <myCustomMaterial
          ref={material}
          color={color}
          transparent
          opacity={opacity}
          tex={map}
          disp={map}
        />
      </mesh>
    );
  }
);

export default Plane;
