import * as THREE from "three";

import { extend } from "@react-three/fiber";

export class MyCustomMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        effectFactor: { value: 1.0 },
        dispFactor: { value: 1.0 },
        tex: { value: undefined },
        disp: { value: undefined },
        hasTexture: { value: 0 },
        scale: { value: 0 },
        shift: { value: 0 },
        opacity: { value: 1 },
        color: { value: new THREE.Color("white") },
      },
      vertexShader: `varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }`,
      fragmentShader: `



      varying vec2 vUv;
      uniform sampler2D tex;
      uniform sampler2D disp;
      uniform float _rot;
      uniform float dispFactor;
      uniform float effectFactor;
      uniform float hasTexture;
      uniform float shift;
      uniform float scale;
      uniform vec3 color;
      uniform float opacity;


      void main() {
        
        vec2 uv = vUv;
        vec4 disp = texture(disp, uv);
        vec2 distortedPosition = vec2(uv.x, uv.y + dispFactor * (disp.r*effectFactor));
        vec2 distortedPosition2 = vec2(uv.x, uv.y - (1.0 - dispFactor) * (disp.r*effectFactor));
        vec4 _texture = texture(tex, distortedPosition);
        vec4 _texture2 = texture(tex, distortedPosition2);
        vec4 finalTexture = mix(_texture, _texture2, dispFactor);
        
        // Seed value
        float v = fract(sin(dispFactor * 1.) * 1000.);
        
        vec4 newTex = texture(tex, uv);
        
        
        
        // Prepare for chromatic Abbreveation
        vec2 ruv = uv;
        vec2 guv = uv;
        vec2 buv = uv;
        
        // Randomize 
        float dispBias = 0.01;
        float amplitude = 20.;


        if (v>.1){
          float y = floor(uv.y * amplitude * sin(dispFactor)) ;
          float x = floor(uv.x * amplitude * sin(dispFactor)) ;
        
          if (sin(50. * y * v) > -0.01) {

            // image row mix moves right left
            ruv.x = fract(uv.x + sin(10. * y * dispFactor) * dispBias);
            guv.x = fract(uv.x + sin(15. * y * dispFactor) * dispBias);
            buv.x = fract(uv.x + sin(100. * y * dispFactor) * dispBias );
            
            //image row mix moves up or down 
            ruv.y = fract(uv.y + sin(10. * y * dispFactor) * dispBias );
            guv.y = fract(uv.y + sin(15. * y * dispFactor) * dispBias );
            buv.y = fract(uv.y + sin(100. * y * dispFactor) * dispBias );
          
          }
          
  
  
          if (sin(50. * x * v) > -0.01) {
            
            //  image column mix moves right left
            ruv.x = fract(uv.x + sin(10. * x * dispFactor) * dispBias  );
            guv.x = fract(uv.x + sin(15. * x * dispFactor) * dispBias  );
            buv.x = fract(uv.x + sin(100. * x * dispFactor) * dispBias  );
            
            // image column mix moves up or down
            ruv.y = fract(uv.y + sin(10. * x * dispFactor) * dispBias  );
            guv.y = fract(uv.y + sin(15. * x * dispFactor) * dispBias  );
            buv.y = fract(uv.y + sin(100. * x * dispFactor) * dispBias  );
           
          }
          


        }

        newTex.r = texture(tex, ruv).r ;
        newTex.g = texture(tex, guv).g ;
        newTex.b = texture(tex, buv).b ;
        newTex *= 1. * opacity;

        // this is to use the same material for images and texts
        if (hasTexture == 1.0) {
          gl_FragColor = newTex;
        } else {
          gl_FragColor = vec4(color, opacity);
        }
      }
      `,
    });
  }

  get effectFactor() {
    return this.uniforms.effectFactor.value;
  }
  set effectFactor(v) {
    return (this.uniforms.effectFactor.value = v);
  }
  get dispFactor() {
    return this.uniforms.dispFactor.value;
  }
  set dispFactor(v) {
    return (this.uniforms.dispFactor.value = v);
  }

  get disp() {
    return this.uniforms.disp.value;
  }
  set disp(v) {
    return (this.uniforms.disp.value = v);
  }

  set scale(value) {
    this.uniforms.scale.value = value;
  }

  get scale() {
    return this.uniforms.scale.value;
  }

  set shift(value) {
    this.uniforms.shift.value = value;
  }

  get shift() {
    return this.uniforms.shift.value;
  }

  set tex(value) {
    this.uniforms.hasTexture.value = !!value;
    this.uniforms.tex.value = value;
  }

  get tex() {
    return this.uniforms.tex.value;
  }

  get color() {
    return this.uniforms.color.value;
  }

  get opacity() {
    return this.uniforms.opacity.value;
  }

  set opacity(value) {
    if (this.uniforms) this.uniforms.opacity.value = value;
  }
}

extend({ MyCustomMaterial });

// precision mediump float;
// uniform vec2 resolution;
// uniform vec2 offset;
// uniform float time;
// uniform sampler2D src;
// float nn(float y, float t) {
//     float n = (
//         sin(y * .07 + t * 8. + sin(y * .5 + t * 10.)) +
//         sin(y * .7 + t * 2. + sin(y * .3 + t * 8.)) * .7 +
//         sin(y * 1.1 + t * 2.8) * .4
//     );
//     n += sin(y * 124. + t * 100.7) * sin(y * 877. - t * 38.8) * .3;
//     return n;
// }
// void main (void) {
//     vec2 uv = (gl_FragCoord.xy - offset) / resolution;
//     vec4 color = texture2D(src, uv);
//     float t = mod(time, 3.14 * 10.);
//     // Seed value
//     float v = fract(sin(t * 2.) * 700.);
//     if (abs(nn(uv.y, t)) < 1.2) {
//         v *= 0.01;
//     }
//     // Prepare for chromatic Abbreveation
//     vec2 focus = vec2(0.5);
//     float d = v * 0.6;
//     vec2 ruv = fract(focus + (uv - focus) * (1. - d));
//     vec2 guv = fract(focus + (uv - focus) * (1. - 2. * d));
//     vec2 buv = fract(focus + (uv - focus) * (1. - 3. * d));
//     // Random Glitch
//     if (v > 0.1) {
//         // Randomize y
//         float y = floor(uv.y * 13. * sin(35. * t)) + 1.;
//         if (sin(36. * y * v) > 0.9) {
//             ruv.x = fract(uv.x + sin(76. * y) * 0.1);
//             guv.x = fract(uv.x + sin(34. * y) * 0.1);
//             buv.x = fract(uv.x + sin(59. * y) * 0.1);
//         }
//         // RGB Shift
//         v = pow(v * 1.5, 2.) * 0.15;
//         color.rgb *= 0.3;
//         color.r += texture2D(src, vec2(uv.x + sin(t * 123.45) * v, uv.y)).r;
//         color.g += texture2D(src, vec2(uv.x + sin(t * 157.67) * v, uv.y)).g;
//         color.b += texture2D(src, vec2(uv.x + sin(t * 143.67) * v, uv.y)).b;
//     }
//     // Compose Chromatic Abbreveation
//     if (abs(nn(uv.y, t)) > 1.1) {
//         color.r = color.r * 0.5 + color.r * texture2D(src, ruv).r;
//         color.g = color.g * 0.5 + color.g * texture2D(src, guv).g;
//         color.b = color.b * 0.5 + color.b * texture2D(src, buv).b;
//         color *= 2.;
//     }
//     gl_FragColor = color;
//     gl_FragColor.a = step(.1, length(color.rgb));
// }

// const commpleteFragmentShadder = `

// varying vec2 vUv;
// uniform sampler2D tex;
// uniform sampler2D disp;
// uniform float _rot;
// uniform float dispFactor;
// uniform float effectFactor;

// float nn(float y, float t) {
//   float n = (
//       sin(y * .07 + t * 8. + sin(y * .5 + t * 10.)) +
//       sin(y * .7 + t * 2. + sin(y * .3 + t * 8.)) * .7 +
//       sin(y * 1.1 + t * 2.8) * .4
//   );
//   n += sin(y * 124. + t * 100.7) * sin(y * 877. - t * 38.8) * .3;
//   return n;
// }
// void main() {

//   vec2 uv = vUv;
//   vec4 disp = texture(disp, uv);
//   vec2 distortedPosition = vec2(uv.x, uv.y + dispFactor * (disp.r*effectFactor));
//   vec2 distortedPosition2 = vec2(uv.x, uv.y - (1.0 - dispFactor) * (disp.r*effectFactor));
//   vec4 _texture = texture(tex, distortedPosition);
//   vec4 _texture2 = texture(tex, distortedPosition2);
//   vec4 finalTexture = mix(_texture, _texture2, dispFactor);

//   // Seed value
//   float v = fract(sin(dispFactor * 2.) * 700.);

//   vec4 color = texture(tex, uv);

//   // Prepare for chromatic Abbreveation
//   vec2 focus = vec2(0.5);
//   float d = v * 0.0001;
//   vec2 ruv = fract(focus + (uv - focus) * (1. - d));
//   vec2 guv = fract(focus + (uv - focus) * (1. - 2. * d));
//   vec2 buv = fract(focus + (uv - focus) * (1. - 3. * d));

//   // Randomize y

//   float y = floor(uv.y * 13. * sin(35. * dispFactor)) + 1.;
//   if (sin(10. * y * v) > 0.01) {
//       ruv.x = fract(uv.x + sin(76. * y) * 0.05);
//       guv.x = fract(uv.x + sin(34. * y) * 0.05);
//       buv.x = fract(uv.x + sin(59. * y) * 0.05);
//   }

//   // Randomize x

//   // float x = floor(uv.x * 13. * sin(35. * dispFactor)) + 1.;

//   // if (sin(36. * x * v) > 0.1) {
//   //     ruv.y = fract(uv.y + sin(76. * x) * 0.1);
//   //     guv.y = fract(uv.y + sin(34. * x) * 0.1);
//   //     buv.y = fract(uv.y + sin(59. * x) * 0.1);
//   // }

//   // RGB Shift
//   // v = pow(v * 1.5, 2.) * 0.15;
//   // color.rgb *=1.1;
//   // color.r += texture(tex, vec2(uv.x + sin(dispFactor * 123.45) * v, uv.y)).r;
//   // color.g += texture(tex, vec2(uv.x + sin(dispFactor * 157.67) * v, uv.y)).g;
//   // color.b += texture(tex, vec2(uv.x + sin(dispFactor * 143.67) * v, uv.y)).b;

//   if (abs(nn(uv.y, dispFactor)) > 0.1) {
//     color.r = color.r * 0.1 + color.r * texture(tex, ruv).r;
//     color.g = color.g * 0.1 + color.g * texture(tex, guv).g;
//     color.b = color.b * 0.1 + color.b * texture(tex, buv).b;
//     color *= 2.;
//   }

//   gl_FragColor = color;
//   gl_FragColor.a = step(.1, length(color.rgb));
// }
// `;

// const completeCrossXY = `

// varying vec2 vUv;
// uniform sampler2D tex;
// uniform sampler2D disp;
// uniform float _rot;
// uniform float dispFactor;
// uniform float effectFactor;

// float nn(float y, float t) {
//   float n = (
//       sin(y * .07 + t * 8. + sin(y * .5 + t * 10.)) +
//       sin(y * .7 + t * 2. + sin(y * .3 + t * 8.)) * .7 +
//       sin(y * 1.1 + t * 2.8) * .4
//   );
//   n += sin(y * 124. + t * 100.7) * sin(y * 877. - t * 38.8) * .3;
//   return n;
// }
// void main() {

//   vec2 uv = vUv;
//   vec4 disp = texture(disp, uv);
//   vec2 distortedPosition = vec2(uv.x, uv.y + dispFactor * (disp.r*effectFactor));
//   vec2 distortedPosition2 = vec2(uv.x, uv.y - (1.0 - dispFactor) * (disp.r*effectFactor));
//   vec4 _texture = texture(tex, distortedPosition);
//   vec4 _texture2 = texture(tex, distortedPosition2);
//   vec4 finalTexture = mix(_texture, _texture2, dispFactor);

//   // Seed value
//   float v = fract(sin(dispFactor * 2.) * 1000.);

//   vec4 color = texture(tex, uv);

//   // Prepare for chromatic Abbreveation

//   vec2 focus = vec2(0.5);
//   float d = v * 0.01;
//   vec2 ruv = fract(focus + (uv - focus) * (1. - d));
//   vec2 guv = fract(focus + (uv - focus) * (1. - d));
//   vec2 buv = fract(focus + (uv - focus) * (1. - d));

//   // Randomize

//   float y = floor(uv.y * 13. * sin(35. * dispFactor)) + 1.;

//   if (sin(10. * y * v) > -0.5) {

//     // image row mix moves 0.05 to the right
//     ruv.x = fract(uv.x + sin(76. * y) * 0.05);
//     guv.x = fract(uv.x + sin(76. * y) * 0.05);
//     buv.x = fract(uv.x + sin(76. * y) * 0.05);

//     //image row mix moves 0.05 up or down
//     ruv.y = fract(uv.y + sin(76. * y) * 0.05);
//     guv.y = fract(uv.y + sin(76. * y) * 0.05);
//     buv.y = fract(uv.y + sin(76. * y) * 0.05);
//   }

//   float x = floor(uv.x * 13. * sin(35. * dispFactor)) + 1.;

//   if (sin(10. * x * v) > -0.5) {

//   //  image column mix moves 0.05 to the right
//     ruv.x = fract(uv.x + sin(76. * x) * 0.05);
//     guv.x = fract(uv.x + sin(76. * x) * 0.05);
//     buv.x = fract(uv.x + sin(76. * x) * 0.05);

//   // image row mix moves 0.05 up or down
//     ruv.y = fract(uv.y + sin(76. * x) * 0.05);
//     guv.y = fract(uv.y + sin(76. * x) * 0.05);
//     buv.y = fract(uv.y + sin(76. * x) * 0.05);
//   }

//   if (abs(nn(uv.y, dispFactor)) > 0.1) {
//     color.r = texture(tex, ruv).r;
//     color.g =  texture(tex, guv).g;
//     color.b =  texture(tex, buv).b;
//     color *= 1.;
//   }

//   gl_FragColor = color;
// }
// `;
