import React, { Suspense, useRef, Fragment } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import lerp from "lerp";
import Plane from "./components/Plane";
import state from "./store";
import "./styles.css";
import { Container } from "./styled";
import Content from "./components/Content";
import { ViewPortContextProvider } from "../../contexts/viewPortContext";
import { withRouter } from "react-router-dom";
import MobileDisclaimer from "./components/MobileDisclaimer";
import useWith from "../../hooks/useWidth";
const Startup = () => {
  const ref = useRef();
  useFrame(
    () =>
      (ref.current.material.opacity = lerp(
        ref.current.material.opacity,
        0,
        0.025
      ))
  );
  return (
    <Plane
      ref={ref}
      color="#0e0e0f"
      position={[0, 0, 200]}
      scale={[100, 100, 1]}
    />
  );
};

const GlitchedHome = ({ history }) => {
  const { width } = useWith();
  const isMobile = width <= 768;

  const onWheel = (e) => {
    if (state.lastElement.current.getBoundingClientRect().y < 0) {
      state.top.current = state.top.current - 100;
    }
    if (state.top.current < 0) {
      state.top.current = 0;
    } else if (state.top.current >= 0 && state.validScroll.current) {
      state.top.current += e.deltaY;
    } else if (e.deltaY < 0) {
      state.top.current += e.deltaY;
    }
  };

  return (
    <Fragment>
      {isMobile ? (
        <MobileDisclaimer />
      ) : (
        <Container onWheelCapture={onWheel}>
          <Canvas
            linear
            dpr={[1, 2]}
            orthographic
            camera={{ zoom: state.zoom, position: [0, 0, 500] }}
          >
            <Suspense
              fallback={
                <Html center className="loading" children="Loading..." />
              }
            >
              <ViewPortContextProvider>
                <Content history={history} />
                <Startup />
              </ViewPortContextProvider>
            </Suspense>
          </Canvas>
        </Container>
      )}
    </Fragment>
  );
};

export default withRouter(GlitchedHome);
