import { createRef } from "react";

const state = {
  sections: 3,
  pages: 3,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "Glitching",
      image: "/glitched1.jpg",
      aspect: 1.51,
      text: "Making images glitch using WebGL (based on the RGB displacement from react-three-fiber...) ",
      link: {
        external: false,
        value: "/glitched",
      },
    },
    // {
    //   offset: 2,
    //   factor: 2.0,
    //   header: "Game Of Life",
    //   image: "/gameOfLife.jpeg",
    //   aspect: 1.5,
    //   text: " Javascript implementation of the Conway's Game of Life. ",
    // },
    // {
    //   offset: 3,
    //   factor: 2.25,
    //   header: "Data Viz",
    //   image: "/d3.png",
    //   aspect: 1.5037,
    //   text: "Plotting things with D3.js ",
    // },
    {
      offset: 2,
      factor: 2.0,
      header: "Playing with NFTs",
      image: "/my-nft-page.png",
      aspect: 1.87,
      text: "First contact with NFTs, all deployed on rinkeby testnet.",
      link: {
        external: true,
        value: "https://infallible-goldberg-e15d7a.netlify.app/",
      },
    },
    // {
    //   offset: 5,
    //   factor: 1.75,
    //   header: "Sector 8",
    //   image: "/photo-1533577116850-9cc66cad8a9b.jpeg",
    //   aspect: 1.55,
    //   text: "Some explanation of the project",
    // },
    // {
    //   offset: 7,
    //   factor: 1.05,
    //   header: "The Factory",
    //   image: "/photo-1548191265-cc70d3d45ba1.jpeg",
    //   aspect: 1.77,
    //   text: "Education and enlightenment.",
    // },
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 },
  ],

  top: createRef(),
  validScroll: createRef(),
  lastElement: createRef(),
};

export default state;
